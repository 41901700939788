<div style="text-align:center">
    <!-- Main navigation -->
<header>
    <!--Navbar-->
    <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark fixed-top scrolling-navbar">
      <mdb-navbar-brand>
        <a class="logo navbar-brand waves-light" mdbWavesEffect routerLink="/home"><strong>MAPP</strong></a>
      </mdb-navbar-brand>
      <links>
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/home">Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/contador">Contador</a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/informatico">Informático</a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" routerLink="/electronico">Electrónico</a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" href="#">Investigador</a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" href="#">Curriculum</a>
          </li>
          <li class="nav-item waves-light" mdbWavesEffect>
            <a class="nav-link" href="#">Fundación</a>
          </li>
        </ul>
      </links>
    </mdb-navbar>
  </header>
  <router-outlet></router-outlet>
  <!-- Chat Messeger -->
  <div class="fb-customerchat"
    attribution=setup_tool
    page_id="110920320711029"
    logged_in_greeting="Hola! bienvenido a mi plataforma personal, si necesitas algo escríbeme"
    logged_out_greeting="Hola! bienvenido a mi plataforma personal, si necesitas algo escríbeme">
  </div>
  <!-- Chat Messeger -->
</div>