import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificado-curso-normas-ortograficas-empresariales',
  templateUrl: './certificado-curso-normas-ortograficas-empresariales.component.html',
  styleUrls: ['./certificado-curso-normas-ortograficas-empresariales.component.scss']
})
export class CertificadoCursoNormasOrtograficasEmpresarialesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
