<div style="text-align:center">
    <app-header></app-header>
    <!--Certificado-->
    <section class="magazine-section my-5">
    <!-- Section heading -->
    <h2 class="h1-responsive font-weight-bold text-center my-5">
        CERTIFICADO CURSO DE EXCEL BÁSICO
    </h2>
    <!--Certificado 1-->
    <div class="btn-group mr-2" role="group" aria-label="First group">
        <button mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect routerLink="/certificadosCursosContables">
            <mdb-icon fas icon="arrow-alt-circle-left"></mdb-icon>
            Volver
        </button>
        <a mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect TARGET="_Blank" 
        href="https://drive.google.com/uc?id=1r_MgIBL2G7DQNJfrUjbLnApvPIMpA6Hd&export=download&authuser=0">
            <mdb-icon fas icon="arrow-alt-circle-down"></mdb-icon>
            Descargar Certificado
        </a>
    </div>
    <br/>
    </section>
    <br/>
    <div class="embed-responsive embed-responsive-16by9"
    style="max-width: 1100px; height: 800px; text-align: center; margin-top: auto; margin-left: auto; margin-right: auto;">
        <iframe src="https://drive.google.com/file/d/1r_MgIBL2G7DQNJfrUjbLnApvPIMpA6Hd/preview" height="2200px"></iframe>
    </div>
</div>   

