import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificado-tecnico-contabilidad-finanzas',
  templateUrl: './certificado-tecnico-contabilidad-finanzas.component.html',
  styleUrls: ['./certificado-tecnico-contabilidad-finanzas.component.scss']
})
export class CertificadoTecnicoContabilidadFinanzasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
