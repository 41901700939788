<!-- Full Page Intro -->
<div class="view" style="background-image: url('https://scontent.fbaq1-1.fna.fbcdn.net/v/t1.0-9/124019359_3803402366361280_3452243746854434887_o.jpg?_nc_cat=108&ccb=2&_nc_sid=825194&_nc_eui2=AeFTjIthKX68t-BCfDoeOMLv-gzn0_fBqHX6DOfT98GodUZkE8wZER_MYEWxWNlKXLh1APrvOSwcsuwbMdTkHkZ3&_nc_ohc=40KuZ4XfXCcAX-OENuf&_nc_ht=scontent.fbaq1-1.fna&oh=201f9e9b775244e6d538cd25a9004f07&oe=5FD16579'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
    <!-- Mask & flexbox options-->
    <div class="mask rgba-gradient d-flex justify-content-center align-items-center">
      <!-- Content -->
      <div class="container">
        <!--Grid row-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-6 white-text text-center text-md-left mt-xl-5 mb-5 wow fadeInLeft" data-wow-delay="0.3s">
            <h1 class="h1-responsive font-weight-bold mt-sm-5">TRANSFORMACIÓN DIGITAL</h1>
            <hr class="hr-light">
            <h6 class="mb-4">
                A mi concepto los paises latino americanos en la última decada han venido viviendo una gran transformación digital,
                seguida de la globalización, la humanidad está viviendo no solo el libre mercado sino la libertad de la información, 
                esto conlleva a tener sociedades del conocimiento y darle mayor importancia a este...
  
            </h6>
            <a mdbBtn color="white" outline="true" mdbWavesEffect>Leer Más</a>
          </div>
          <!--Grid column-->
          <!--Grid column-->
          <div class="col-md-6 col-xl-5 mt-xl-5 wow fadeInRight" data-wow-delay="0.3s">
            <img src="https://mdbootstrap.com/img/Mockups/Transparent/Small/admin-new.png" alt="" class="img-fluid">
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </div>
      <!-- Content -->
    </div>
    <!-- Mask & flexbox options-->
  </div>
  <!-- Full Page Intro -->
