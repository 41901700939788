import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificado-tecnologo-contabilidady-finanzas',
  templateUrl: './certificado-tecnologo-contabilidady-finanzas.component.html',
  styleUrls: ['./certificado-tecnologo-contabilidady-finanzas.component.scss']
})
export class CertificadoTecnologoContabilidadyFinanzasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
