import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-electronico-page',
  templateUrl: './electronico-page.component.html',
  styleUrls: ['./electronico-page.component.scss']
})
export class ElectronicoPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
