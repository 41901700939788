<div style="text-align:center">
    <app-header></app-header>
    <div style="max-width: 1100px; text-align: center; margin-top: auto; margin-left: auto; margin-right: auto;">    
    <!--Section: Magazine v.3-->
<section class="magazine-section my-5">
    <!-- Section Titulo -->
    <h2 class="h1-responsive font-weight-bold text-center my-5">Cursos que he realizado enfocados a la carrera contable</h2>
    <!-- Section description -->
    <p class="text-center w-responsive mx-auto mb-5">"La educación es la herramienta más
        poderosa para cambiar el mundo"</p>
    <!--separador 1 -->
    <hr class="my-5">
    <!--separador 1 -->
    <button mdbBtn type="button" color="default" mdbWavesEffect routerLink="/contador">
    <i class="fas fa-angle-double-left"></i> Volver</button>
    <br/>
    <br/>  
    <!--linea 1 -->
    <div class="row">
      <!--inicia el curso 1-->
      <div class="col-lg-4 col-md-12 mb-lg-0 mb-5">
        <div class="single-news mb-3">
          <!-- imagen del curso 1 -->
          <div class="view overlay rounded z-depth-2 mb-4">
            <img class="img-fluid"
            src="https://scontent.fbog11-1.fna.fbcdn.net/v/t1.0-9/124273131_3809585769076273_5272028935850772204_n.jpg?_nc_cat=106&ccb=2&_nc_sid=825194&_nc_eui2=AeEQjHkrAwHntFRSkVVtxFAIzrJGLYVdwYbOskYthV3BhlbkRCXTZDYDnwCw2HmzGakOL-GZqsx2IkiwY3T7Z6GU&_nc_ohc=npB8iPfQAOwAX-Bw14Z&_nc_ht=scontent.fbog11-1.fna&oh=c5ad0eba2b37a83b4d4be226acc5a6d3&oe=5FD2FC45"
            alt="Sample image">
            <a routerLink="/certificadoCursoTablasExcel">
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!-- imagen del curso 1 -->
          <!-- abrir certificado -->
          <div class="row mb-3">
            <div class="col-12">
              <a routerLink="/certificadoCursoTablasExcel">
                <i class="fas fa-external-link-alt"></i>
                  Ver certificado de estudio
              </a>
            </div>
          </div>
          <!-- abrir certificado -->
          <!-- Titulo del curso -->
          <div class="d-flex justify-content-between">
            <div class="col-11 text-truncate pl-0 mb-3">
              <a class="font-weight-bold" routerLink="/certificadoCursoTablasExcel">Curso de: <br/> "Elaboración de 
                  cálculos y tablas <br/> financieras con micrososft excel"
              </a>
            </div>
          </div>
          <!-- Titulo del curso -->
        </div>
      </div>
      <!--termina el curso 1-->
      <!--inicia el curso 2-->
      <div class="col-lg-4 col-md-12 mb-lg-0 mb-5">
        <div class="single-news mb-3">
          <!-- imagen del curso 2 -->
          <div class="view overlay rounded z-depth-2 mb-4">
            <img class="img-fluid"
            src="https://scontent.fctg2-1.fna.fbcdn.net/v/t1.0-9/124583783_3811854972182686_292704907123451782_n.jpg?_nc_cat=100&ccb=2&_nc_sid=825194&_nc_eui2=AeG3m6B6sh_1738MmCIOTcdPgieYFfdDC1GCJ5gV90MLUcAYvFOUTkx95PR0g5UmYA8Hbp7PDDF2GoeRDUWjVG_V&_nc_ohc=hZlFJu7DbWsAX-p4Efg&_nc_ht=scontent.fctg2-1.fna&oh=cac3835981f0561692a967adca3af922&oe=5FD4D9A2"
            alt="Sample image">
            <a routerLink="/certificadoCursoExcelBasico">
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!-- imagen del curso 2 -->
          <!-- abrir certificado -->
          <div class="row mb-3">
            <div class="col-12">
              <a routerLink="/certificadoCursoExcelBasico">
                <i class="fas fa-external-link-alt"></i>
                  Ver certificado de estudio
              </a>
            </div>
          </div>
          <!-- abrir certificado -->
          <!-- Titulo del curso -->
          <div class="d-flex justify-content-between">
            <div class="col-11 text-truncate pl-0 mb-3">
              <a class="font-weight-bold" routerLink="/certificadoCursoExcelBasico">Curso de: <br/> "Excel Básico"
              </a>
            </div>
          </div>
          <!-- Titulo del curso -->
        </div>
      </div>
      <!--termina el curso 2-->
      <!--inicia el curso 3-->
      <div class="col-lg-4 col-md-12 mb-lg-0 mb-5">
        <div class="single-news mb-3">
          <!-- imagen del curso 3 -->
          <div class="view overlay rounded z-depth-2 mb-4">
            <img class="img-fluid"
            src="https://scontent.fctg2-1.fna.fbcdn.net/v/t1.0-9/125267173_3811948535506663_2907188908033550901_n.jpg?_nc_cat=108&ccb=2&_nc_sid=825194&_nc_eui2=AeEDkFkJDhCLYfscyFusyGuh_LJ6LGZM2LX8snosZkzYtbQtVtp7OqAv_e6M-PTw5NfWKiCNrzOO3Ylzgy_hsTjl&_nc_ohc=nT92NXcN9XEAX__CoUR&_nc_ht=scontent.fctg2-1.fna&oh=8250eae6e7ab61342c65952fe206a181&oe=5FD50D6E"
            alt="Sample image">
            <a routerLink="/certificadoCursoHerramientasOffice">
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!-- imagen del curso 3 -->
          <!-- abrir certificado -->
          <div class="row mb-3">
            <div class="col-12">
              <a routerLink="/certificadoCursoHerramientasOffice">
                <i class="fas fa-external-link-alt"></i>
                  Ver certificado de estudio
              </a>
            </div>
          </div>
          <!-- abrir certificado -->
          <!-- Titulo del curso -->
          <div class="d-flex justify-content-between">
            <div class="col-11 text-truncate pl-0 mb-3">
              <a class="font-weight-bold" routerLink="/certificadoCursoHerramientasOffice">Curso de: <br/> "MANEJO DE HERRAMIENTAS <br/>
                 MICROSOFT OFFICE"
              </a>
            </div>
          </div>
          <!-- Titulo del curso -->
        </div>
      </div>
      <!--termina el curso 3-->
    </div>
    <!--linea 1 -->
    <!--separador 2 -->
    <hr class="my-5">
    <!--separador 2 -->
    <button mdbBtn type="button" color="default" mdbWavesEffect routerLink="/contador">
    <i class="fas fa-angle-double-left"></i> Volver</button>
    <br/>
    <br/>  
    <!--linea 2 -->
    <div class="row">
      <!--inicia el curso 4-->
      <div class="col-lg-4 col-md-12 mb-lg-0 mb-5">
        <div class="single-news mb-3">
          <!-- imagen del curso 4 -->
          <div class="view overlay rounded z-depth-2 mb-4">
            <img class="img-fluid"
            src="https://scontent.fctg2-1.fna.fbcdn.net/v/t1.0-9/124366797_3812037148831135_3240604670338186331_n.jpg?_nc_cat=107&ccb=2&_nc_sid=825194&_nc_eui2=AeHdzywZhSpK94KtCDPajzksxvBvDJWA7pbG8G8MlYDulvcR-7cphXifE0O3nFEdBmXotjbRIKVy8z50_DtQrVq9&_nc_ohc=e4ZwkKZAL64AX8w0fkH&_nc_ht=scontent.fctg2-1.fna&oh=ff5ea4badd119e4ad0fa8c2866cefe76&oe=5FD3B2AC"
            alt="Sample image">
            <a routerLink="/certificadoCursoTablasExcel">
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!-- imagen del curso 4 -->
          <!-- abrir certificado -->
          <div class="row mb-3">
            <div class="col-12">
              <a routerLink="/certificadoCursoTablasExcel">
                <i class="fas fa-external-link-alt"></i>
                  Ver certificado de estudio
              </a>
            </div>
          </div>
          <!-- abrir certificado -->
          <!-- Titulo del curso -->
          <div class="d-flex justify-content-between">
            <div class="col-11 text-truncate pl-0 mb-3">
              <a class="font-weight-bold" routerLink="/certificadoCursoTablasExcel">Curso de: <br/> 
                APLICACIÓN DE <br/> NORMAS Y REGLAS ORTOGRÁFICAS <br/> EN LA REDACCIÓN DE <br/> DOCUMENTOS EMPRESARIALES
              </a>
            </div>
          </div>
          <!-- Titulo del curso -->
        </div>
      </div>
      <!--termina el curso 4-->
      <!--inicia el curso 5-->
      <div class="col-lg-4 col-md-12 mb-lg-0 mb-5">
        <div class="single-news mb-3">
          <!-- imagen del curso 5 -->
          <div class="view overlay rounded z-depth-2 mb-4">
            <img class="img-fluid"
            src="https://scontent.fctg2-1.fna.fbcdn.net/v/t1.0-9/124583783_3811854972182686_292704907123451782_n.jpg?_nc_cat=100&ccb=2&_nc_sid=825194&_nc_eui2=AeG3m6B6sh_1738MmCIOTcdPgieYFfdDC1GCJ5gV90MLUcAYvFOUTkx95PR0g5UmYA8Hbp7PDDF2GoeRDUWjVG_V&_nc_ohc=hZlFJu7DbWsAX-p4Efg&_nc_ht=scontent.fctg2-1.fna&oh=cac3835981f0561692a967adca3af922&oe=5FD4D9A2"
            alt="Sample image">
            <a routerLink="/certificadoCursoExcelBasico">
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!-- imagen del curso 5 -->
          <!-- abrir certificado -->
          <div class="row mb-3">
            <div class="col-12">
              <a routerLink="/certificadoCursoExcelBasico">
                <i class="fas fa-external-link-alt"></i>
                  Ver certificado de estudio
              </a>
            </div>
          </div>
          <!-- abrir certificado -->
          <!-- Titulo del curso -->
          <div class="d-flex justify-content-between">
            <div class="col-11 text-truncate pl-0 mb-3">
              <a class="font-weight-bold" routerLink="/certificadoCursoExcelBasico">Curso de: <br/> "Excel Básico"
              </a>
            </div>
          </div>
          <!-- Titulo del curso -->
        </div>
      </div>
      <!--termina el curso 5-->
      <!--inicia el curso 6-->
      <div class="col-lg-4 col-md-12 mb-lg-0 mb-5">
        <div class="single-news mb-3">
          <!-- imagen del curso 6 -->
          <div class="view overlay rounded z-depth-2 mb-4">
            <img class="img-fluid"
            src="https://scontent.fctg2-1.fna.fbcdn.net/v/t1.0-9/125267173_3811948535506663_2907188908033550901_n.jpg?_nc_cat=108&ccb=2&_nc_sid=825194&_nc_eui2=AeEDkFkJDhCLYfscyFusyGuh_LJ6LGZM2LX8snosZkzYtbQtVtp7OqAv_e6M-PTw5NfWKiCNrzOO3Ylzgy_hsTjl&_nc_ohc=nT92NXcN9XEAX__CoUR&_nc_ht=scontent.fctg2-1.fna&oh=8250eae6e7ab61342c65952fe206a181&oe=5FD50D6E"
            alt="Sample image">
            <a routerLink="/certificadoCursoHerramientasOffice">
              <div class="mask rgba-white-slight waves-light" mdbWavesEffect></div>
            </a>
          </div>
          <!-- imagen del curso 6 -->
          <!-- abrir certificado -->
          <div class="row mb-3">
            <div class="col-12">
              <a routerLink="/certificadoCursoHerramientasOffice">
                <i class="fas fa-external-link-alt"></i>
                  Ver certificado de estudio
              </a>
            </div>
          </div>
          <!-- abrir certificado -->
          <!-- Titulo del curso -->
          <div class="d-flex justify-content-between">
            <div class="col-11 text-truncate pl-0 mb-3">
              <a class="font-weight-bold" routerLink="/certificadoCursoHerramientasOffice">Curso de: <br/> "MANEJO DE HERRAMIENTAS <br/>
                 MICROSOFT OFFICE"
              </a>
            </div>
          </div>
          <!-- Titulo del curso -->
        </div>
      </div>
      <!--termina el curso 6-->
    </div>
    <!--linea 2 -->
  </section>
  <!--Section-->
  </div>
</div>    

