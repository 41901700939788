<div style="text-align:center">
    <app-header></app-header>
    <!--Certificado-->
    <section class="magazine-section my-5">
    <!-- Section heading -->
    <h2 class="h1-responsive font-weight-bold text-center my-5">
        CERTIFICADO DE TECNÓLOGO EN CONTABILIDAD Y FINANZAS
    </h2>
    <!--Certificado 1-->
    <div class="btn-group mr-2" role="group" aria-label="First group">
        <button mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect routerLink="/contador">
            <mdb-icon fas icon="arrow-alt-circle-left"></mdb-icon>
            Volver
        </button>
        <a mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect TARGET="_Blank" 
        href="https://drive.google.com/uc?id=1ZbXaS9NXNiOTKjazwuNpguyZ-1H86vM6&export=download&authuser=0">
            <mdb-icon fas icon="arrow-alt-circle-down"></mdb-icon>
            Descargar Certificado
        </a>
    </div>
    <br/>
    </section>
    <br/>
    <div class="embed-responsive embed-responsive-16by9"
    style="max-width: 1100px; height: 1400px; text-align: center; margin-top: auto; margin-left: auto; margin-right: auto;">
        <iframe src="https://drive.google.com/file/d/1ZbXaS9NXNiOTKjazwuNpguyZ-1H86vM6/preview" height="2200px"></iframe>
    </div>
    <br/>
    <br/>
    <!--Certificado 2-->
    <div class="btn-group mr-2" role="group" aria-label="First group">
        <button mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect routerLink="/contador">
            <mdb-icon fas icon="arrow-alt-circle-left"></mdb-icon>
            Volver
        </button>
        <a mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect TARGET="_Blank" 
        href="https://drive.google.com/uc?id=12mZtaHfziz07BMmjWN5HA16vDcfXmnhp&export=download&authuser=0">
            <mdb-icon fas icon="arrow-alt-circle-down"></mdb-icon>
            Descargar Certificado
        </a>
    </div>
    <br/>
    <br/>
    <div class="embed-responsive embed-responsive-16by9"
    style="max-width: 1100px; height: 2200px; text-align: center; margin-top: auto; margin-left: auto; margin-right: auto;">
        <iframe src="https://drive.google.com/file/d/12mZtaHfziz07BMmjWN5HA16vDcfXmnhp/preview" ></iframe>
    </div>
    <br/>
    <br/>
    <!--Certificado 3-->
    <div class="btn-group mr-2" role="group" aria-label="First group">
        <button mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect routerLink="/contador">
            <mdb-icon fas icon="arrow-alt-circle-left"></mdb-icon>
            Volver
        </button>
        <a mdbBtn type="button" color="default" class="waves-light" mdbWavesEffect TARGET="_Blank" 
        href="https://drive.google.com/uc?id=18OAGArCZK9-LLuj0rhq0ObosuVjuazxR&export=download&authuser=0">
            <mdb-icon fas icon="arrow-alt-circle-down"></mdb-icon>
            Descargar Certificado
    </a>
    </div>
    <br/>
    <br/>
    <div class="embed-responsive embed-responsive-16by9"
    style="max-width: 1100px; height: 1400px; text-align: center; margin-top: auto; margin-left: auto; margin-right: auto;">
        <iframe src="https://drive.google.com/file/d/18OAGArCZK9-LLuj0rhq0ObosuVjuazxR/preview" height="2200px"></iframe>
    </div>
  <!--Certificado-->
</div>    
