<header>
    <div class="view intro-2" style="height: 280px;">
        <div class="row">                 
            <!--Imagen de perfil-->
            <div class="imagenPerfil">
                <img src="https://scontent.fctg2-1.fna.fbcdn.net/v/t1.0-9/123063769_155774156225645_2383598193006832660_n.jpg?_nc_cat=103&ccb=2&_nc_sid=09cbfe&_nc_eui2=AeElLT6YLseWpeUXo0PluBcyzH34gR7kBZTMffiBHuQFlHxKQ56GiFPhvdwXZEhD4GekuxBJRgwmqOx7uVkSYQVU&_nc_ohc=c0GdfpwKk80AX8Wr_a7&_nc_ht=scontent.fctg2-1.fna&oh=9c3764a21ff3a9ea526d03ba29ee4d0d&oe=5FC8895E"
                    class="img-fluid z-depth-1 rounded-circle"
                    style="height: 150px; width: 150px"
                    alt="Responsive image">
                </div>
            <!--Imagen de perfil-->
            <!--Texto en el Centro-->
            <div class="nombre" style="text-align: center;">
                <div class="white-text text-center wow fadeInUp">
                    <h1>Miguel Ángel Páez Parra</h1>
                </div>
            </div>
            <!--Texto en el Centro-->
            <div class="buttonSocial">
                <!--Facebook-->
                <a type="button" TARGET="_Blank" href="https://www.facebook.com/MiguelPaezUnidigital" class="facebook"><mdb-icon fab icon="facebook-f"></mdb-icon></a>
                <!--Twitter-->
                <a type="button" TARGET="_Blank" href="https://twitter.com/miguelpaez96" class="twitter"><mdb-icon fab icon="twitter"></mdb-icon></a>
                <!--Linkedin-->
                <a type="button" TARGET="_Blank" href="https://www.linkedin.com/in/miguel-%C3%A1ngel-p%C3%A1ez-parra-461129187/" class="linkedin"><mdb-icon fab icon="linkedin-in"></mdb-icon></a>
                <!--Youtube-->
                <a type="button" TARGET="_Blank" href="https://www.youtube.com/channel/UCJ2T0CzrD1Tl2WCXXZP_yTA" class="youtube"><mdb-icon fab icon="youtube"></mdb-icon></a>    
            </div>
        </div>
    </div>
</header>