<div style="text-align:center">
    <app-header></app-header>
    <div style="max-width: 1100px; text-align: center; margin-top: auto; margin-left: auto; margin-right: auto;">
    <!-- Contador -->
        <section class="my-5">
        <!-- Section heading -->
            <h2 class="h1-responsive font-weight-bold text-center my-5">Contaduría Pública</h2>
        <!-- Section description -->
            <p class="grey-text text-center w-responsive mx-auto mb-5">
                En esta sección puedo compartirles mi experiencia laboral, educativa y mi aporte a la sociedad
                para el crecimiento intelectual de la misma.
            </p>
        <!-- Grid row -->
            <hr class="my-5">
            <div class="row">
        <!-- Grid column -->
                <div class="col-lg-5 mb-lg-0 mb-5">
                <!--Image-->
                    <img src="https://scontent.fbog10-1.fna.fbcdn.net/v/t1.0-9/124859331_3804008062967377_4238451398526616844_o.jpg?_nc_cat=109&ccb=2&_nc_sid=825194&_nc_eui2=AeGSIsYJ4HYbiXNbZnSthRGWpZlI2xAD1wGlmUjbEAPXAf36LyT_wi-NcSNdf3x7hEdRfOA976GXOtHLPM86yYFX&_nc_ohc=xBYLzKnXiL4AX-tzig3&_nc_ht=scontent.fbog10-1.fna&oh=11e91035bc96230f36f599ac804e9e54&oe=5FCF8ABB" alt="Sample project image" class="img-fluid rounded z-depth-1">
                </div>
        <!-- Grid column -->
        <!-- Grid column -->
                <div class="col-lg-7">
        <!-- Contaduría Publica titulo -->
                <div class="row mb-3">
                    <div class="col-md-1 col-2">
                        <mdb-icon fas icon="book" size="2x" class="cyan-text"></mdb-icon>
                    </div>
                    <div class="col-md-11 col-10">
                    <h5 class="font-weight-bold mb-3">CONTADURÍA PÚBLICA</h5>
                    <p class="grey-text">Me encuentro cursando los últimos dos semestres de la carrera
                        de contaduría pública, estaré siempre con todo el optimismo para culminarla 
                        y tener diariamente un mejor crecimiento intelectual.
                    </p>
                    </div>
                </div>
        <!-- Contaduría Publica titulo -->
        <!-- Certificado tecnologo -->
        <div class="row mb-3">
          <div class="col-md-1 col-2">
            <mdb-icon fas icon="calculator" size="2x" class="red-text"></mdb-icon>
          </div>
          <div class="col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">TECNÓLOGO EN CONTABILIDAD Y FINANZAS</h5>
            <p class="grey-text">Culminé mi educación en tecnología en contabilidad 
              y finanzas en el SENA ("Servicio Nacional de Aprendizaje"), gracias 
              a dicha capacitación he podido comprender muchos procesos administrativos
              de las operaciones que realiza una entidad diariamente y comprender
              la importancia de registrar las mismas operaciones para controlar y diagnosticar
              la vida financiera de la entidad.
            </p>
            <button mdbBtn type="button" gradient="aqua" rounded="true" mdbWavesEffect routerLink="/certificadoTecnologoContabilidadyFinanzas">
              Ver Certificado de Estudio <i class="fas fa-external-link-alt"></i>
            </button>
          </div>
        </div>
        <br/>
        <!-- Certificado tecnologo -->
        <!-- Certificado tecnico -->
        <div class="row">
          <div class="col-md-1 col-2">
            <mdb-icon fas icon="chart-line" size="2x" class="deep-purple-text"></mdb-icon>
          </div>
          <div class="col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">TÉCNICO EN CONTABILIZACIÓN DE OPERACIONES COMERCIALES Y FINANCIERAS</h5>
            <p class="grey-text mb-0">Para inicios en un mundo financiero y contable fue de gran ayuda poder aprender
               y capacitarme en esta disciplina, siendo el técnico mi iniciación a este mundo de operaciones administrativas
               y contables, este título me sirvió de inspiración para continuar y ejercer con satisfacción esta bonita profesión.
            </p>
            <button mdbBtn type="button" gradient="blue" rounded="true" mdbWavesEffect routerLink="/certificadoTecnicoContabilidadFinanzas">
            Ver Certificado de Estudio <i class="fas fa-external-link-alt"></i></button>
          </div>
        </div>
        <br/>
        <!-- Certificado tecnico -->
        <!-- Certificado cursos -->
        <div class="row">
          <div class="col-md-1 col-2">
            <mdb-icon fas icon="chalkboard-teacher" size="2x" class="blue-text"></mdb-icon>
          </div>
          <div class="col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">CURSOS COMPLEMENTARIOS DE LA CARRERA CONTABLE</h5>
            <p class="grey-text mb-0">Con el fin de tener un mayor crecimiento intelectual, es muy necesario realizar 
              cursos que fortalezcan los vacíos educativos e inclusive nos llenen de más conocimiento.
            </p>
            <button mdbBtn type="button" gradient="purple" rounded="true" mdbWavesEffect routerLink="/certificadosCursosContables">
            Ver Certificados de Estudio <i class="fas fa-external-link-alt"></i></button>
          </div>
        </div>
        <!-- Certificado cursos -->
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
    <!--Linea segunda sección-->
    <hr class="my-5">
    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-lg-7">
        <!-- Grid row -->
        <div class="row mb-3">
          <div class="col-md-1 col-2">
            <mdb-icon fas icon="chart-bar" size="2x" class="indigo-text"></mdb-icon>
          </div>
          <div class="col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">Marketing</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam,
              aperiam
              minima elit assumenda
              voluptate velit.</p>
          </div>
        </div>
        <!-- Grid row -->
  
        <!-- Grid row -->
        <div class="row mb-3">
          <div class="col-md-1 col-2">
            <mdb-icon fas icon="music" size="2x" class="pink-text"></mdb-icon>
          </div>
          <div class="col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">Entertainment</h5>
            <p class="grey-text">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores nam,
              aperiam
              minima elit assumenda
              voluptate velit.</p>
          </div>
        </div>
        <!-- Grid row -->
  
        <!-- Grid row -->
        <div class="row mb-lg-0 mb-5">
          <div class="col-md-1 col-2">
            <mdb-icon far icon="smile" size="2x" class="blue-text"></mdb-icon>
          </div>
          <div class="col-md-11 col-10">
            <h5 class="font-weight-bold mb-3">Communication</h5>
            <p class="grey-text mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing. Reprehenderit maiores
              nam,
              aperiam minima elit assumenda
              voluptate velit.</p>
          </div>
        </div>
        <!-- Grid row -->
  
      </div>
      <!-- Grid column -->
  
      <!-- Grid column -->
      <div class="col-lg-5">
        <!--Image-->
        <img src="https://mdbootstrap.com/img/Photos/Others/images/82.jpg" alt="Sample project image" class="img-fluid rounded z-depth-1">
      </div>
      <!-- Grid column -->
  
    </div>
    <!-- Grid row -->
  
  </section>
  <!-- Projects section v.3 -->
    </div>
</div>
